@tailwind base;
@tailwind components;
@tailwind utilities;
.group:hover .group-hover\:block {
    display: block !important;
  }
@import url('https://fonts.cdnfonts.com/css/verdana');

.uploadcare--widget_button.uploadcare--widget_button_type_open {
  background-color: rgb(20 184 166);
  color: white;

}
.uploadcare--widget__button_type_open {
  background-color: rgb(20 184 166);
  color: white;
}
.uploadcare--button{
  background-color: rgb(0, 2, 95);
}
.uploadcare--widget__drag{
color: rgb(20 184 166);

}

.tst {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}